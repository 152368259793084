import React, { useState, useEffect, useCallback } from "react";

import clsx from "clsx";

import Tabs from "~/components/Common/Tabs";
import { LaboratoriesDisplay } from "~/components/Laboratory";
import TubeLoader from "~/components/Loaders/TubeLoader";
import { labAPI } from "~/utils/api/v2";
import { CODE_TO_COUNTRY, COUNTRY_CODES } from "~/utils/data/constants";
import type { LaboratoryWithStats } from "~/utils/interfaces/Laboratory";
import type { Locale } from "~/utils/interfaces/Locale";

const LabsPage = () => {
  const [country, setCountry] = useState<Locale>(COUNTRY_CODES[0]);
  const [laboratories, setLaboratories] = useState<LaboratoryWithStats[]>([]);
  const [inactiveLaboratories, setInactiveLaboratories] = useState<LaboratoryWithStats[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchLaboratories = useCallback(async () => {
    setLoading(true);
    const activeLabs = await labAPI.listStats({ country, active: true });
    const inactiveLabs = await labAPI.listStats({ country, active: false });
    if (activeLabs) {
      setLaboratories(activeLabs);
    }
    if (inactiveLabs) {
      setInactiveLaboratories(inactiveLabs);
    }
    setLoading(false);
  }, [country]);

  useEffect(() => {
    fetchLaboratories();
  }, [fetchLaboratories]);

  return (
    <div
      className={clsx(
        "w-full",
        "px-5 py-4",
        "flex flex-col gap-y-5 bg-examedi-white-pure",
        "rounded-lg border border-examedi-gray-4",
      )}
    >
      <h1 className="text-4xl font-light">Administrador de Laboratorios</h1>
      <Tabs
        elements={COUNTRY_CODES}
        parser={(value: string) => CODE_TO_COUNTRY[value as Locale]}
        onSelectTab={(value: string) => setCountry(value as Locale)}
      >
        {!loading && (
          <LaboratoriesDisplay
            laboratories={laboratories}
            inactiveLaboratories={inactiveLaboratories}
            country={country}
            fetchLaboratoriesCallback={fetchLaboratories}
          />
        )}
        {loading && (
          <div className="w-full h-110 flex items-center justify-center">
            <TubeLoader />
          </div>
        )}
      </Tabs>
    </div>
  );
};

export default LabsPage;
